.gradient {
  background: linear-gradient(135deg,
  rgb(50, 210, 255) 30%,
  rgb(0, 146, 255) 100%);
}

.gradient.gradient--text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grecaptcha-badge {
  visibility: hidden;
}
